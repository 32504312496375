import { ModelAdapter } from 'src/app/core/model-adapter.interface';
import { IDropDown } from 'src/app/shared/utils/iui-list-item';

export class AccountModelAdapter
  implements ModelAdapter<Account, IUserProfile>
{
  fromDto(dto: Account): IUserProfile {
    return {
      token: dto.token,
      userName: dto.userName,
      validatUntill: dto.validatUntill,
      refreshToken: dto.refreshToken,
      userId: dto.userId,
      coreUserId: dto.coreUserId,
      guidId: dto.guidId,
      sessionId: dto.sessionId,
      role: dto.role,
      fullName: dto.fullName,
      clinicName: dto.clinicName,
      clinicId: dto.clinicId,
      clinics: dto.clinics,
      forcePasswordChange: dto.forcePasswordChange,
      passwordExpired: dto.passwordExpired,
      chooseClinic: dto.chooseClinic,
      isADUser: dto.isADUser,
      twoFactorAuthentication: dto.twoFactorAuthentication,
      userType:dto.userType

    };
  }

  toDto(model: IUserProfile): Account {
    return {
      token: model.token,
      userName: model.userName,
      validatUntill: model.validatUntill,
      refreshToken: model.refreshToken,
      userId: model.userId,
      coreUserId: model.coreUserId,
      guidId: model.guidId,
      sessionId: model.sessionId,
      role: model.role,
      fullName: model.fullName,
      clinicName: model.clinicName,
      clinicId: model.clinicId,
      clinics: model.clinics,
      forcePasswordChange: model.forcePasswordChange,
      passwordExpired: model.passwordExpired,
      chooseClinic: model.chooseClinic,
      isADUser: model.isADUser,
      twoFactorAuthentication: model.twoFactorAuthentication,
      userType:model.userType

    };
  }
}

export class Account {
  token: string;
  userName: string;
  validatUntill: Date;
  refreshToken: string;
  userId: number;
  coreUserId: string;
  guidId: string;
  sessionId: string;
  role: string;
  fullName: string;
  clinicName: string;
  clinicId: number;
  clinics: IDropDown[];
  twoFactorAuthentication: boolean;
  forcePasswordChange: boolean;
  passwordExpired: boolean;
  chooseClinic: boolean;
  isADUser: boolean;
  userType:UserTypes
}

export type UserTypes ='public'|'private'|'';

export interface IUserProfile {
  token: string;
  userName: string;
  validatUntill: Date;
  refreshToken: string;
  userId: number;
  coreUserId: string;
  guidId: string;
  sessionId: string;
  role: string;
  fullName: string;
  clinicName: string;
  clinicId: number;
  clinics: IDropDown[];
  twoFactorAuthentication: boolean;
  forcePasswordChange: boolean;
  passwordExpired: boolean;
  chooseClinic: boolean;
  isADUser: boolean;
  userType:UserTypes
}


export interface UserProfile {
  userProfile:UserProfileDetails,
  loaded:boolean
}


export class UserProfileDetails {
  userName: string;
  userId: number;
  coreUserId: number;
  userRole: string;
  fullName: string;
  clinicName: string;
  clinicId: number;
}


export type Roles = 'Provider' | 'Doctor';

export type TokenTypes = 'Atoken' | 'Rtoken';

export interface IPassRecovery {
  userName: string;
}
export interface ITempPassChange {
  userName: string;
}
export interface IUserPassChange {
  userName: string;
}
