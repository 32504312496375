import { computed, inject, Injectable, signal } from '@angular/core';
import { of } from 'rxjs';
import { AppSettings, defaults } from '../config';
import { LocalStorageService } from '../shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CoreService {

  readonly localStorage = inject(LocalStorageService);

  private optionsVal = signal<any>(defaults);
  public options = computed(this.optionsVal);

  getDefaultOptions() {
    return of(defaults);
  }

  setOptions(options: AppSettings) {
    this.optionsVal.update((x) => (x = Object.assign(defaults, options)));
  }

  public setActiveMode(mode: string) {
    this.localStorage.saveKey('mode', mode);
    // this.optionsVal.update((value) =>
    //   value.map((x: any) => ({ ...x, theme: mode }))
    // );
    this.optionsVal.update((x) => (x = Object.assign(defaults, { theme: mode })));
  }

}
