
<ng-container *transloco="let t">

  
<p *alertData="alertData"></p>

<mat-toolbar class="topbar">

  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  @if(showToggle) {
  <button
    mat-icon-button
    (click)="toggleCollapsed.emit()"
    class="d-flex justify-content-center"
>
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <!-- Mobile Menu -->
  @if(!showToggle) {
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <span class= "f-s-14  caps-regular">
    {{t(pageName)}}
  </span>

  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
   <!-- <button
    mat-icon-button
    (click)="openDialog()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
  </button>  -->

  <!-- <div class="d-none d-lg-flex">

    <button
      mat-button
      [matMenuTriggerFor]="appsmenu"
      aria-label="Notifications"
    >
      <div class="d-flex align-items-center">
        Apps <i-tabler name="chevron-down" class="icon-16 m-l-4"></i-tabler>
      </div>
    </button>
    <mat-menu #appsmenu="matMenu" class="apps-dd cardWithShadow">
      <div class="row">
        <div class="col-sm-8 b-r-1 p-r-0">
          <div class="p-32 p-b-0">
            <div class="row">
              @for(appdd of apps; track appdd.title) {
              <div class="col-sm-6 text-hover-primary">
                <a
                  [routerLink]="[appdd.link]"
                  class="d-flex align-items-center text-decoration-none m-b-24"
                >
                  <span
                    class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center"
                  >
                    <img [src]="appdd.img" width="20" />
                  </span>

                  <div class="m-l-16">
                    <h5
                      class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text"
                    >
                      {{ appdd.title }}
                    </h5>
                    <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                  </div>
                </a>
              </div>
              }
            </div>
          </div>
          <div
            class="b-t-1 p-24 d-none d-lg-flex align-items-center justify-content-between"
          >
            <span
              class="d-flex align-items-center mat-subtitle-1 f-s-16 f-w-500"
            >
              <i-tabler name="help" class="icon-20 m-r-8"></i-tabler>Frequently
              Asked Questions
            </span>
            <a
              [routerLink]="['/theme-pages/faq']"
              mat-flat-button
              color="primary"
              >Check</a
            >
          </div>
        </div>
        <div class="col-sm-4">
          <div class="p-x-16 p-y-32">
            <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">Quick Links</h4>
            @for(quicklink of quicklinks; track quicklink.title) {
            <div class="text-hover-primary">
              <a
                [routerLink]="[quicklink.link]"
                class="hover-text text-decoration-none mat-body-1 f-w-600 d-block p-y-8"
                >{{ quicklink.title }}</a
              >
            </div>
            }
          </div>
        </div>
      </div>
    </mat-menu>
    <a class="caps-medium" mat-button [routerLink]="['/calendar/day']">{{t('Calendar')}} </a>
  </div>  -->

  <span class="flex-1-auto"></span>

  <!-- Mobile Menu -->
  <button
    mat-icon-button
    (click)="toggleMobileFilterNav.emit()"
    class="d-flex d-lg-none justify-content-center"
  >
    <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
  </button>

  <div >

    @if(userProfile()){
      
      @if (userProfile().fullName) {
        <span  class= "f-s-14  caps-regular clinic-name-web">
          {{userProfile().fullName}}
        </span>
      }
      @if (userProfile().clinicName) {
        <span class="f-s-12 caps-regular clinic-name-web sub-title">
          {{userProfile().clinicName}}
        </span>
      }
    }
 
</div>
  

  <!-- --------------------------------------------------------------- -->
  <!-- langugage Dropdown  temp turn off-->
  <!-- --------------------------------------------------------------- -->
  <!-- <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <img
      [src]="activeLangIcon()"
      class="rounded-circle object-cover icon-20"
    />
  </button>
  <mat-menu #flags="matMenu" class="cardWithShadow">
    @for(lang of languages(); track lang.icon) {
    <button mat-menu-item (click)="changeLanguage(lang)">
      <div class="d-flex align-items-center">
        <img
          [src]="lang.icon"
          class="rounded-circle object-cover m-r-8 icon-20"
        />
        <span class="mat-subtitle-1 f-s-14 caps-uppercase">{{ lang.code}}</span>
      </div>
    </button>
    }
  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- [matMenuTriggerFor]="notificationmenu" -->
  <button
    mat-icon-button
    aria-label="Notifications"
    (click)="onNavView()"
    [routerLink]="'notifications/notification-list'"
  >
  <span matBadge="5" matBadgeColor="warn" 
  [ngClass]="'custom-badge'">
    <img
      class="d-flex"
      width="40"
      height="40"
      ngSrc="../../../../assets/images/svgs/notification.svg"
    />
  </span>
  </button>
  <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1 caps-medium">{{t('Notification')}}</h6>
      <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12"
          >5 {{t('New')}}</span>
      </span>
    </div>

    @for(notification of notifications; track notification.title) {
    <button mat-menu-item class="p-x-32 p-y-16" (click)="viewNotificationDetails(notification)">
      <div class="d-flex align-items-center">
        <!-- <img [src]="notification.img" class="rounded-circle" width="48" /> -->
        <div class="m-l-16">
          <h5  class="f-s-14 f-w-600 m-0 mat-subtitle-1 caps-medium">
            {{ notification.title }}
          </h5>
          <span class="caps-regular">{{ notification.subtitle }}</span>
        </div>
      </div>
    </button>
    }
    <div class="p-y-12 p-x-32">

      <app-button
      (btnClick)="viewAllNotifications()"
      [text]="t('See_all')"
      [className]="'stroked'">
      </app-button>
    
    </div>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="Notifications"
  >
    <img  
      src="/assets/images/svgs/icon-account.svg"
      class="rounded-circle object-cover icon-35 profile-dd"
      width="18" height="18"
    />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
      <!-- p-y-16 -->
      <div class="p-x-32">
        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img
            src="/assets/images/svgs/icon-account.svg"
            class="rounded-circle"
            width="95"
          />
          @if (userProfile()) {
          <div class="m-l-16">

            <h6  class="f-s-14 f-w-600 m-0 mat-subtitle-1 caps-uppercase">{{userProfile().fullName}}</h6>
            <span class="f-s-14 d-block m-b-4 caps-regular">{{userProfile().clinicName}}</span>
            <a *ngxPermissionsOnly="['Doctor']"  class="profile-address caps-medium" routerLink="/profile-details">{{t('User_Profile')}}</a>
            <a *ngxPermissionsOnly="['Provider']"  class="profile-address caps-medium" routerLink="/provider-profile-details">{{t('User_Profile')}}</a>
         
            <span class="f-s-14 m-b-4 caps-regular clinic-name-mob">
              {{userProfile().clinicName}}
            </span>
     
          </div>
        }
        </div>
      </div>

      <div class="p-y-12 p-x-32">
        <app-button
        [text]="t('LogOut')"
        (btnClick)="onLogOut()"
        className="stroked"

        ></app-button>
   
      </div>
  </mat-menu>
</mat-toolbar>

</ng-container>
