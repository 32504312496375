import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AlertComponent } from '../components/alert/alert.component';

export interface AlertMessageParams {
  statusCode: number;
}

export type MessageType = 'alert' | 'UImsg';
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private _snackBar: MatSnackBar) { }

  notification({
    message,
    horizontalPosition = 'right',
    verticalPosition = 'top',
    messageType = 'success',
    duration = 6000,
    action,
    showClose,
  }: {
    message: string;
    horizontalPosition?: MatSnackBarHorizontalPosition;
    verticalPosition?: MatSnackBarVerticalPosition;
    messageType?: 'success' | 'error' | 'warning';
    duration?: number;
    action?: string;
    showClose?: boolean
  }) {
    let style = 'success-dialog';
    if (messageType == 'error') style = 'error-dialog';
    this._snackBar.openFromComponent(AlertComponent, {
      data: { message, messageType, showClose, action },
      horizontalPosition,
      verticalPosition,
      duration,
      panelClass: [style]
    })
  }

  errors = [
    {
      statusArr: [
        {
          statusCode: 1,
          message: 'Success',
        },
        {
          statusCode: -1,
          message: 'Invalid_User_Or_Password',
        },
        {
          statusCode: -2,
          message: 'IP_Restriction',
        },
        {
          statusCode: -3,
          message: 'User_Blocked_For_Wrong_Password',
        },
        {
          statusCode: -4,
          message: 'User_Blocked',
        },
        {
          statusCode: -5,
          message: 'User_Not_Active',
        },
        {
          statusCode: -6,
          message: 'Error_Creating_Session',
        },
        {
          statusCode: -7,
          message: 'Sms_Send_Error',
        },
        {
          statusCode: -8,
          message: 'Token_Is_Empty',
        },
        {
          statusCode: -9,
          message: 'Token_Is_Invalid',
        },
        {
          statusCode: -10,
          message: 'Session_Not_Found',
        },
        {
          statusCode: -11,
          message: 'Failed_Password_Validation',
        },
        {
          statusCode: -12,
          message: 'Reuse_Previous_Password',
        },
        {
          statusCode: -13,
          message: 'Invalid_Session_Type',
        },
        {
          statusCode: -14,
          message: 'User_Not_Found',
        },
        {
          statusCode: -15,
          message: 'AdUserPassWord_Reset_Restriction',
        },
        {
          statusCode: -16,
          message: 'SmsResend_Restriction',
        },
        {
          statusCode: -17,
          message: 'Invalid_Otp',
        },
        {
          statusCode: -18,
          message: 'Clinic_Not_Found',
        },
        {
          statusCode: -19,
          message: 'AdUser_PassWord_Change_Restriction',
        },
        {
          statusCode: -20,
          message: 'Wrong_Current_Password',
        },
        {
          statusCode: -21,
          message: 'Refresh_Token_Generating_Error',
        },
        {
          statusCode: -22,
          message: 'Password_Contains_Personal_Data',
        },
        {
          statusCode: -9999,
          message: 'General_Error',
        },
      ],
    },
  ];

  getMessageTxt(alertTxtParams: AlertMessageParams) {
    const statusCode = this.errors[0].statusArr.find(
      (x) => x.statusCode === alertTxtParams.statusCode
    );
    return statusCode && statusCode.message || 'common_error';
  }
}
