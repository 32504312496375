import { ComponentRef, Directive, EmbeddedViewRef, HostListener, inject, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AlertData, NotificationMessageComponent } from '../components/notification-message/notification-message.component';

@Directive({
  selector: '[alertData]',
  standalone: true
})
export class NotificationDirective implements OnInit, OnChanges  {


  private readonly templateRef = inject(TemplateRef);
  private readonly vcRef = inject(ViewContainerRef);
  @Input() alertData: AlertData
  templateView: EmbeddedViewRef<any>;
  loaderRef: ComponentRef<NotificationMessageComponent>;

  ngOnInit() {
    console.log(this.alertData)
    this.templateView = this.templateRef.createEmbeddedView({});
    this.loaderRef = this.vcRef.createComponent(NotificationMessageComponent, {
      injector: this.vcRef.injector,
      projectableNodes: [this.templateView.rootNodes],
    });

    this.loaderRef.setInput('alertData', this.alertData);
  }

  ngOnChanges() {
    this.loaderRef?.setInput('alertData', this.alertData);
    setTimeout(() => {
      this.alertData.loading = false
      this.loaderRef?.setInput('alertData', this.alertData);

    }, 60000);
  }


}
