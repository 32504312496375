<!-- ============================================================== -->
<!-- Only router without any element -->
<!-- ============================================================== -->
<mat-sidenav-container
  [ngClass]="{
    'cardBorder': options.cardBorder,
    blue_theme: options.activeTheme == 'blue_theme',
    purple_theme: options.activeTheme == 'purple_theme',
  }"
  [dir]="options.dir!"
>
  <router-outlet></router-outlet>
</mat-sidenav-container>
