import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SharedModule } from '../../shared-module/shared';
import { MaterialModule } from '../../shared-module/material.module';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [SharedModule,MaterialModule,RouterModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: {
          message: string;
          messageType?: 'success' | 'error' | 'warning';
          action?: string;
          showClose?: boolean
        },
        private snackBarRef: MatSnackBarRef<AlertComponent>,
      ) {
      }
    
    
      onClose() {
        this.snackBarRef.dismiss();
      }

}
