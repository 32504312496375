import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  signal,
} from '@angular/core';
import {  Observable, tap } from 'rxjs';
import { JwtService } from './jwt.service';
import { Account, UserProfile, UserProfileDetails } from '../utils/account';
import { ApiService } from 'src/app/core/api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  CompleteAuthSession,
  CompleteChooseClinicSession,
  ConfirmOtp,
  IUpdatePassword,
  Login,
  RefreshToken,
} from '../utils/auth';
import { ApiResponseBase } from 'src/app/core/utils/models';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

const basePath: string = '/Account';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  destroyRef = inject(DestroyRef);
  private readonly apiService = inject(ApiService);
  private readonly jwtService = inject(JwtService);
  private readonly localStorageService = inject(LocalStorageService);
  readonly permissionsService = inject(NgxPermissionsService);

  private account = signal<Account>(new Account());
  public accountState = computed(this.account);

  userProfileInit = {
    userProfile: new UserProfileDetails(),
    loaded: false,
  };

  private userProfileDetails = signal<UserProfile>(this.userProfileInit);

  public userProfileState = computed(
    () => this.userProfileDetails().userProfile
  );
  public userProfileLoaded = computed(() => this.userProfileDetails().loaded);


 public login(params: Login) {
    return this.apiService.post(`${basePath}/Login`, params).pipe(
      tap((res: ApiResponseBase<Account>) => {
        if (res.code !== 1) return;
        else{
          if(this.isFirstTryLogin(res.data!) ) {
            this.setAccount(res.data!);
            this.getUserProfileInfo();
          }
        }
      })
    );
  }

 private isFirstTryLogin (account:Account){
    return !account?.forcePasswordChange&&
    !account?.passwordExpired&&
    !account?.twoFactorAuthentication&&
    !account?.chooseClinic
  }
  private getTokenInfo(tokenData: any) {
    this.account.update((state) => ({
      ...state,
      token: this.jwtService.getToken('Atoken'),
      fullName: tokenData.decodedTokenInfo.FullName,
      clinicName: tokenData.decodedTokenInfo.ClinicName,
      role: tokenData.decodedTokenInfo.UserRole,
      userType:tokenData.decodedTokenInfo.UserType
    }));

    this.getUserProfileInfo();
  }

  private setPermissions(roles: string[]) {
    this.permissionsService.loadPermissions(roles);
  }

  private setAccount(account: Account) {
    this.jwtService.saveToken('Atoken', account.token!);
    this.jwtService.saveToken('Rtoken', account.refreshToken!);

    this.account.update((state) => ({
      ...account,
      role: account.role,
    }));
    this.setPermissions([account.role]);
  }

 public completeChooseClinicSession(params: CompleteChooseClinicSession) {
    return this.apiService
      .post(`${basePath}/CompleteChooseClinicSession`, params)
      .pipe(
        tap((res: ApiResponseBase<Account>) => {
          this.setAccount(res.data!);
          this.getUserProfileInfo();
        })
      );
  }

 public completeAuthSession(params: CompleteAuthSession) {
    return this.apiService.post(`${basePath}/CompleteAuthSession`, params).pipe(
      tap((res:ApiResponseBase<Account>)=>{
        if (res.code !== 1) return;
        else{
          let account =res.data
          if(account&&!account.twoFactorAuthentication&&!account.chooseClinic) {
            this.setAccount(res.data!);
            this.getUserProfileInfo();
          }
        }
      })
    )
  }

 public createForgetPasswordSession(username: string) {
    return this.apiService.post(
      `${basePath}/CreateForgetPasswordSession?username=${username}`
    );
  }

 public confirmForgetPasswordSessionOtp(params: ConfirmOtp) {
    return this.apiService.post(
      `${basePath}/ConfirmForgetPasswordSessionOtp`,
      params
    );
  }

 public completeTwoFactorySession(params: ConfirmOtp) {
    return this.apiService
      .post(`${basePath}/CompleteTwoFactorySession`, params)
      .pipe(
        tap((res: ApiResponseBase<Account>) => {
          if (!res.data?.chooseClinic) {
            this.setAccount(res.data!);
            this.getUserProfileInfo();
          }
        })
      );
  }

 public resendOtp(sessionId: string) {
    return this.apiService.post(`${basePath}/ResendOtp?sessionId=${sessionId}`);
  }

 private getUserProfileInfo() {
    this.apiService
      .post(`${basePath}/GetUserProfileInfo`)
      .subscribe({
        next: (userProfileDetails:ApiResponseBase<UserProfileDetails>) => {
          this.userProfileDetails.update((state) => ({
            loaded: true,
            userProfile: userProfileDetails.data!,
          }));
        },
        error: (err) => {
          console.error(err);
          this.userProfileDetails.update((state) => ({
            ...state,
            loaded: true,
          }));
        },
      });
  };

 public updatePassword(params: IUpdatePassword): Observable<ApiResponseBase<boolean>> {
    return this.apiService.post(`${basePath}/UpdatePassword`, params);
  }

  getRefreshToken(params: RefreshToken) {
    return this.apiService.post(`${basePath}/RefreshToken`, params);
  }

  refreshToken(): Observable<any> {
    var refreshToken = this.jwtService.getToken('Rtoken');
    if (refreshToken == undefined) {
      throw 'Refresh token not found';
    }
    return this.getRefreshToken({ refreshToken: refreshToken }).pipe(
      tap((refreshResult: ApiResponseBase<Account>) =>
        this.setAccount(refreshResult.data!)
      )
    );
  }

 public populate(tokenData: any) {
    var jwt = this.jwtService.getToken('Atoken') ?? '';
    if (jwt) {
      this.getTokenInfo(tokenData);

    } else {
      this.logOut();
    }
  }

 public logOut() {
    this.jwtService.destroyToken('Atoken');
    this.jwtService.destroyToken('Rtoken');
    this.localStorageService.destroyKey('mediLang');
    this.account.update((x) => (x = {} as Account));
    this.userProfileDetails.update((x) => (x = {} as UserProfile));
  }
}
