import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '../../shared-module/shared';
export interface AlertData {
  loading: boolean;
  messageTxt: string;
  redirectUrl?: string;
  value?: number
}
@Component({
  selector: 'app-alert-message',
  standalone: true,
  styles: [
    `

@mixin font($size, $weight, $color) {
    font-size: $size;
    font-weight: $weight;
    color: $color;
    // font-family: $font;
  }

  ::ng-deep{
    .mainWrapper{
      .blocker {
        width: calc(100vw - 320px);
        left: calc(50% + 270px);
        transform: translateX(calc(-50% - 140px));
      }
    } 
    .mainWrapper.sidebarNav-mini{
      .blocker {
        width: calc(100vw - 139px);
        left: 50%;
        transform: translateX(-47.5%);
      }
    } 
    @media (max-width: 800px) {
      .blocker {
        width: 100% !important;
        left: initial !important;
        transform: initial !important;
      }
    }
    
  }
      
      .blocker{
        background:#FD07071A;
            z-index: 11;
            transition:200ms;
            padding:15px;
            opacity: 0.5;
            display: block;
            position: fixed;
            text-align: center;
            margin-top: 50px;
            border-radius: 100px;
      }
      .blocker-inner {
        place-self: center center;
        @include font(15px, 700, #ff0000);
        .view{
          background:#FFFFFF;
          border-radius:8px;
          color:#7C8FAC;
          padding:5px;
          margin-left:10px;
          cursor:pointer;
          @include font(12px, 400,#7C8FAC);
        }
      }
      .img {
         display:flex;
          align-items:center;
          position: absolute; 
          right: 0; 
          margin-right:20px;
          z-index:9999;
          margin-top:-15px;
          cursor:pointer;
        }
    `,
  ],
  imports: [SharedModule],
  template: ` 

  <ng-container *transloco="let t">
  <div class="loading-container">
    <ng-content></ng-content>
    <div *ngIf="alertData.loading" class="blocker">
      <div class="blocker-inner caps-medium" >
        {{ alertData.messageTxt }} <span  (click)="onNavigate()" class="view"> {{t('See')}}</span>
      </div>
      <div class="img" (click)="onClose()">
      <img width="14" height="14"  ngSrc="../../../../assets/images/svgs/red-close.svg" >
      </div>
    </div>
  </div>
  </ng-container>
  `,
})
export class NotificationMessageComponent implements OnInit {
  @Input() alertData: AlertData;
  router = inject(Router);

  ngOnInit(): void { }

  onNavigate() {
    this.router.navigate([this.alertData.redirectUrl]);
    this.alertData.loading = false;
  }

  onClose() {
    this.alertData.loading = false;
  }
}
