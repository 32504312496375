import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  inject,
  OnInit,
  DestroyRef,
} from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { TablerIconsModule } from 'angular-tabler-icons';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { CommonModule, NgIf } from '@angular/common';

import { MaterialModule } from 'src/app/shared/shared-module/material.module';

import { AppSearchDialogComponent } from '../../search-dialog-component';
import { navItems } from '../sidebar/sidebar-data';
import { SharedModule } from 'src/app/shared/shared-module/shared';

import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import {
  LanguageTypes,
  TranslationService,
} from 'src/app/shared/services/translation.serive';
import { ButtonComponent } from '../../button/button.component';
import { AccountService } from 'src/app/auth/data-access/account.service';
import {
  AlertData,
  NotificationMessageComponent,
} from '../../notification-message/notification-message.component';
import { NotificationDirective } from 'src/app/shared/directives/notification.directive';
import { TranslocoService } from '@ngneat/transloco';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface notifications {
  id: number;
  // img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    TablerIconsModule,
    MaterialModule,
    SharedModule,
    ButtonComponent,
    NotificationDirective,
    NotificationMessageComponent,
  ],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  showFiller = false;

  router = inject(Router);
  route = inject(ActivatedRoute);
  readonly translate = inject(TranslationService);
  readonly accountService = inject(AccountService);
  readonly transloco = inject(TranslocoService);
  // userDetails = this.accountService.accountState;
  userProfile = this.accountService.userProfileState;

  constructor(
    private vsidenav: CoreService,
    public dialog: MatDialog,
    private localStorage: LocalStorageService
  ) {}

  pageName: string;

  navItems = navItems;
  @Input() mode: any;

  navItemsData = this.navItems.filter((navitem) => navitem.displayName);

  protected readonly translationService = inject(TranslationService);
  activeLangIcon = this.translate.activeLangState;
  languages = this.translate.availableLangState;
  destroyRef = inject(DestroyRef);

  changeLanguage(lang: LanguageTypes): void {
    this.translate.setActiveLanguage(lang);
  }
  ngOnInit(): void {
    this.route.children[0].children[0].data
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.pageName = res['pageName'];
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.route.data.subscribe((data) => {
          this.route.children[0].children[0].data.subscribe((res) => {
            this.pageName = res['pageName'];
          });
        });
      });
  }

  onRouterChange() {}

  alertData: AlertData = {
    loading: true,
    messageTxt: `${this.transloco.translate(
      'You_have'
    )} ${2} ${this.transloco.translate('Reminder')}`,
    redirectUrl: 'notifications/notification-list',
  };

  onNavView() {
    this.router.navigate(['notifications', 'notification-list']);
    this.alertData.loading = false;
  }

  onNotificationView() {
    this.alertData.loading = !this.alertData.loading;
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent, {
      width: '600px',
      height: '500px',
      data: { 
        dialogData: this.navItems,
         title: 'Patient_history' 
        },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onLogOut() {
    this.router.navigate(['']);
    this.accountService.logOut();
  }

  viewNotificationDetails(notification: notifications) {
    this.router.navigate([
      'notifications',
      'view-notification-list',
      notification.id,
    ]);
    // TODO navigate into particular details
  }

  viewAllNotifications() {
    // TODO navigate into table
    this.router.navigate(['notifications', 'view-all']);
  }

  notifications: notifications[] = [
    {
      id: 1,
      // img: '/assets/images/profile/user-1.jpg',
      title: 'შეტყობინება 1',
      subtitle: 'შეტყობინება 1 ქვესათაური',
    },
    {
      id: 2,
      // img: '/assets/images/profile/user-2.jpg',
      title: 'შეტყობინება 2',
      subtitle: 'შეტყობინება 2 ქვესათაური',
    },
    {
      id: 3,
      // img: '/assets/images/profile/user-3.jpg',
      title: 'შეტყობინება 3',
      subtitle: 'შეტყობინება 3 ქვესათაური',
    },
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'My Profile',
      subtitle: 'Account Settings',
      link: '/',
    },
  ];

  apps: apps[] = [
    // {
    //   id: 1,
    //   img: '/assets/images/svgs/icon-dd-chat.svg',
    //   title: 'Chat Application',
    //   subtitle: 'Messages & Emails',
    //   link: '/apps/chat',
    // },
    {
      id: 2,
      img: '/assets/images/svgs/icon-dd-cart.svg',
      title: 'Todo App',
      subtitle: 'Completed task',
      link: '/apps/todo',
    },
    // {
    //   id: 3,
    //   img: '/assets/images/svgs/icon-dd-invoice.svg',
    //   title: 'Invoice App',
    //   subtitle: 'Get latest invoice',
    //   link: '/apps/invoice',
    // },
    // {
    //   id: 4,
    //   img: '/assets/images/svgs/icon-dd-date.svg',
    //   title: 'Calendar App',
    //   subtitle: 'Get Dates',
    //   link: '/calendar',
    // },
    // {
    //   id: 5,
    //   img: '/assets/images/svgs/icon-dd-mobile.svg',
    //   title: 'Contact Application',
    //   subtitle: '2 Unsaved Contacts',
    //   link: '/apps/contacts',
    // },
    // {
    //   id: 6,
    //   img: '/assets/images/svgs/icon-dd-lifebuoy.svg',
    //   title: 'Tickets App',
    //   subtitle: 'Create new ticket',
    //   link: '/apps/tickets',
    // },
    // {
    //   id: 7,
    //   img: '/assets/images/svgs/icon-dd-message-box.svg',
    //   title: 'Email App',
    //   subtitle: 'Get new emails',
    //   link: '/apps/email/inbox',
    // },
    // {
    //   id: 8,
    //   img: '/assets/images/svgs/icon-dd-application.svg',
    //   title: 'Courses',
    //   subtitle: 'Create new course',
    //   link: '/apps/courses',
    // },
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
    // {
    //   id: 2,
    //   title: 'Authentication Design',
    //   link: '/authentication/login',
    // },
    // {
    //   id: 3,
    //   title: 'Register Now',
    //   link: '/authentication/side-register',
    // },
    // {
    //   id: 4,
    //   title: '404 Error Page',
    //   link: '/authentication/error',
    // },
    // {
    //   id: 5,
    //   title: 'Notes App',
    //   link: '/apps/notes',
    // },
    // {
    //   id: 6,
    //   title: 'Employee App',
    //   link: '/apps/employee',
    // },
    // {
    //   id: 7,
    //   title: 'Todo Application',
    //   link: '/apps/todo',
    // },
    // {
    //   id: 8,
    //   title: 'Treeview',
    //   link: '/theme-pages/treeview',
    // },
  ];
}
