import { Component, Input, OnInit, output } from '@angular/core';
import { LoadingDirective } from '../../directives/loading.directive';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgIf,
    TranslocoModule,
    LoadingDirective,
    MatProgressSpinner,
    MatIconModule,
  ],
  styleUrls: ['./button.component.scss'],
  template: `
    <ng-container *transloco="let t">
      <!-- [style.disabled]="isDisabled||isLoading" -->
      <button
        class="btn button caps-medium {{ className }}"
        [style.width]="width + '%'"
        [style.width]="widthPx + 'px'"
        [style.color]="color"
        [style.border-radius]="borderRadius"
        [style.cursor]="cursor"
        [type]="type"
        [disabled]="isDisabled || isLoading"
        (click)="onClick()"
      >
        <!-- [style.background-color]="bgColor" -->
        @if(isLoading) {
        <mat-spinner class="mat-spinner" [diameter]="'32'"> </mat-spinner>
        }@else {
        <span>{{ text }}</span>

        @if(icon){
        <mat-icon>{{ icon }}</mat-icon>
        } }
      </button>
    </ng-container>
  `,
})
export class ButtonComponent implements OnInit {
  @Input() text: string;
  @Input() color: string = 'primary';
  @Input() className: string = '';

  // @Input() bgColor: string = '$purple';
  @Input() isDisabled: boolean;
  @Input() type: string = 'button';
  @Input() width: string = '100';
  @Input() widthPx: string = '';
  @Input() borderRadius: string = 'none';
  @Input() icon!: string;
  @Input() isLoading: boolean | null = false;
  @Input() cursor: string;

  btnClick = output<void>();

  ngOnInit(): void {}

  onClick() {
    this.btnClick.emit();
  }
}
