import {
  Component,
  Output,
  EventEmitter,
  ViewEncapsulation,
  signal,
} from '@angular/core';
import { AppSettings } from 'src/app/config';
import { CoreService } from 'src/app/services/core.service';
import { TablerIconsModule } from 'angular-tabler-icons';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/shared-module/material.module';
import { BrandingComponent } from 'src/app/shared/components/layout/sidebar/branding.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-customizer',
  standalone: true,
  imports: [
    BrandingComponent,
    TablerIconsModule,
    MaterialModule,
    FormsModule,
    TranslocoModule
  ],
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomizerComponent {
  @Output() optionsChange = new EventEmitter<AppSettings>();
  hideSingleSelectionIndicator = signal(true);
  constructor(private settings: CoreService) { }
  options = this.settings.options()

  setDark() {
    this.optionsChange.emit(this.options);
    this.settings.setActiveMode(this.options.theme)
  }

  setColor() {
    this.optionsChange.emit(this.options);
  }

  setDir() {
    // this.optionsChange.emit(this.options);
  }

  setSidebar() {
    // this.optionsChange.emit(this.options);
  }
}
