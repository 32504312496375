import {
  AfterViewInit,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { PatternTypes, ValidationHelper } from '../pipes/validationHelper.pipe';
import { TranslocoService } from '@ngneat/transloco';

export interface AdditionalValue {
  submit: boolean;
  patternTypes: PatternTypes;
}
@Directive({
  selector: '[validationErrors]',
  standalone: true,
})
export class ValidationErrorsDirective implements OnChanges, AfterViewInit {
  constructor(
    private container: ViewContainerRef,
    private template: TemplateRef<Object>,
    private translateService:TranslocoService
  ) {



  }

  @Input('validationErrorsControl')
  name: string = '';

  @Input('validationErrorsLabel')
  label?: string;

  @Input('validationErrors')
  formGroup?: FormGroup;
  @Input('validationErrorsAdditionalValue') additionalData?: AdditionalValue;

//   translate =inject(TranslateService)
  ngAfterViewInit() {
    let formatter = new ValidationHelper(this.translateService)
    if (this.formGroup && this.name) {
      setTimeout(() => {
        let control = this.formGroup?.get(this.name);
        if (control) {
          control.statusChanges.subscribe(() => {
            if (this.container.length > 0) {
              this.container.clear();
            }
            if (control && control.dirty && control.invalid && control.errors) {
              this.createView(formatter, control);
            }
          });
        }
      }, 0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let formatter = new ValidationHelper(this.translateService)
    if (changes && changes['additionalData']) {
      let submitted = changes['additionalData'].currentValue.submit;
      let control = this.formGroup?.get(this.name);

      if (this.container.length > 0) {
        this.container.clear();
      }
      if (control && submitted) {
        this.createView(formatter, control);
      }
    }
  }

  private createView(formatter: ValidationHelper, control: AbstractControl) {


    formatter
      .format(
        control.errors,
        this.label ?? this.name,
        this.additionalData?.patternTypes
      )
      .forEach((err) => {
        this.container.createEmbeddedView(this.template, { $implicit:err });
      });
  }
}
