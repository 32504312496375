import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { BlankComponent } from './shared/components/layout/blank/blank.component';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { authClinicGuard } from './shared/guards/auth-clinic.guard';
import { authDoctorGuard } from './shared/guards/auth-doctor.guard';
import { FullComponent } from './shared/components/layout/sidebar/full.component';
import { navGuard } from './shared/guards/nav.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';


@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title}`);
    }
  }
}

export const titleResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot
) => route.routeConfig?.path?.replace('-', ' ') ?? '';


export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/check-permissions', pathMatch: 'full' },
      {
        path: 'check-permissions',
        canActivate: [navGuard],
        pathMatch: 'full',
        loadChildren: () => {
          return import(
            './features/doctor-dashboard/feature/doctor-dashboard.routes'
          ).then((m) => m.doctorDashboardRoutes);
        },
      },
      {
        path: 'doctor-dashboard',
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        data: {
          mode: 'doctor-dashboard',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }
        },
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/doctor-dashboard/feature/doctor-dashboard.routes'
          ).then((m) => m.doctorDashboardRoutes);
        },
      },
      {
        path: 'clinic-dashboard',
        data: {
          mode: 'clinic-dashboard',
          permissions: {
            only: ['Provider'],
            redirectTo: 'check-permissions'
          }
        },
        canActivate: [NgxPermissionsGuard, authClinicGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/clinic-dashboard/feature/clinic-dashboard.routers'
          ).then((m) => m.clinicDashboardRoutes);
        },
      },
      {
        path: 'calendar/:time',
        data: {
          mode: 'calendar',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }
        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/calendar/calendar.routers'
          ).then((m) => m.calendarRoutes);
        },
      },
      {
        path: 'visit',
        data: {
          mode: 'visit',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }

        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/visits/visit.routers'
          ).then((m) => m.visitRoutes);
        },
      },
      {
        path: 'client-visit',
        data: {
          mode: 'client-visit',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }

        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/client-visits/client-visit.routers'
          ).then((m) => m.clientVisitRoutes);
        },
      },
      {
        path: 'ambulance/:id',
        data: {
          mode: 'ambulance',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }

        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/ambulance/calendar.routers'
          ).then((m) => m.calendarRoutes);
        },
      },
      {
        path: 'referral-subscription',
        data: {
          mode: 'referral-subscription',
          permissions: {
            only: ['Doctor', "Provider"],
            redirectTo: 'check-permissions'
          }

        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/referral-subscription/feature/referral-subscription.routers'
          ).then((m) => m.referralSubscriptionRoutes);
        },
      },
      {
        path: 'cursus',
        data: {
          mode: 'cursus',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }
        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/cursus/cursus.routers'
          ).then((m) => m.cursusRoutes);
        },
      },
      {
        path: 'profile-details',
        data: {
          mode: 'profile-details',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }
        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/profile-details/profile-details.routers'
          ).then((m) => m.profileDetailsRoutes);
        },
      },
      {
        path: 'provider-profile-details',
        data: {
          mode: 'profived-profile-details',
          permissions: {
            only: ['Clinic'],
            redirectTo: 'check-permissions'
          }
        },
        // canActivate: [NgxPermissionsGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/provider-profile-details/provider-profile-details.routers'
          ).then((m) => m.providerProfileDetailsRoutes);
        },
      },
      {
        path: 'patient-history',
        data: {
          mode: 'patient-history',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }
        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/patient-history/patient-history.routers'
          ).then((m) => m.patientHistoryRoutes);
        },
      },
      {
        path: 'notifications',
        data: {
          mode: 'notifications',
          permissions: {
            only: ['Doctor'],
            redirectTo: 'check-permissions'
          }
        },
        canActivate: [NgxPermissionsGuard, authDoctorGuard],
        title: titleResolver,
        loadChildren: () => {
          return import(
            './features/notifications/notifications.routers'
          ).then((m) => m.notificationRoutes);
        },
      },

      // {
      //   path: 'ui-components',
      //   loadChildren: () =>
      //     import('./pages/ui-components/ui-components.routes').then(
      //       (m) => m.UiComponentsRoutes
      //     ),
      // },
      // {
      //   path: 'forms',
      //   loadChildren: () =>
      //     import('./pages/forms/forms.routes').then((m) => m.FormsRoutes),
      // },
      // {
      //   path: 'charts',
      //   loadChildren: () =>
      //     import('./pages/charts/charts.routes').then((m) => m.ChartsRoutes),
      // },
      // {
      //   path: 'apps',
      //   loadChildren: () =>
      //     import('./pages/apps/apps.routes').then((m) => m.AppsRoutes),
      // },
      // {
      //   path: 'widgets',
      //   loadChildren: () =>
      //     import('./pages/widgets/widgets.routes').then((m) => m.WidgetsRoutes),
      // },
      // {
      //   path: 'tables',
      //   loadChildren: () =>
      //     import('./pages/tables/tables.routes').then((m) => m.TablesRoutes),
      // },
      // {
      //   path: 'datatable',
      //   loadChildren: () =>
      //     import('./pages/datatable/datatable.routes').then(
      //       (m) => m.DatatablesRoutes
      //     ),
      // },
      // {
      //   path: 'theme-pages',
      //   loadChildren: () =>
      //     import('./pages/theme-pages/theme-pages.routes').then(
      //       (m) => m.ThemePagesRoutes
      //     ),
      // },
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        title: titleResolver,
        loadChildren: () =>
          import('./auth/feature/authentication.routes').then(
            (m) => m.AuthenticationRoutes
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/error',
  },
];
