import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  computed,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [FormsModule, MatInputModule, TranslocoModule, MatIconModule],
  template: `
    <ng-container *transloco="let t">
      <mat-form-field
        appearance="fill"
        color="primary"
        class="text-input-cont"
        class="hide-hint w-100"
      >
        <!-- class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block" -->
        <mat-label>{{ placeholder }}</mat-label>
        <input
          style="width: 95%;"
          matInput
          autocomplete="off"
          [type]="type || 'text'"
          [maxlength]="maxlength"
          [minlength]="minlength"
          [max]="max"
          [min]="min"
          [placeholder]="placeholder"
          [readonly]="readonly"
          [disabled]="isDisabled"
          [(ngModel)]="value"
          (ngModelChange)="updateModel()"
          (focus)="onFocusChanged(true)"
          (blur)="onFocusChanged(false)"
          (keydown)="onKeyDown($event)"
          #inputEl
        />
        @if(iconType==='eye'&&value) {
        <span class="icon" (click)="onIconClick()">
          <img [src]="genEyeIconState()" />
        </span>
        }
        @if(iconType==='search') {
        <span class="icon">
          <img src="../../../../assets/images/svgs/search.svg"/>
        </span>
        }
      </mat-form-field>
    </ng-container>
  `,

  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit {
  value!: string;

  @Input() isDisabled: boolean;
  @Input() type: 'text' | 'password' | 'email';
  @Input() placeholder: string;
  @Input() placeholderOnDisable!: string;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() max: number;
  @Input() min: number;
  @Input() readonly: boolean;
  @Input() autofocus: boolean;
  @Input() isValueSelected: boolean;
  @Input() maskValue: string;
  @Input() isRequired: boolean;
  @Input() keyDown: boolean;
  @Input() iconType: string;

  @Output() focusChanged = new EventEmitter<boolean>();
  @Output() keyDownEmit = new EventEmitter<boolean>();
  @ViewChild('inputEl') input!: ElementRef;

  readonly cdRef = inject(ChangeDetectorRef);

  private onModelChange = (_: any) => {};

  ngOnInit(): void {}

  propagateTouched = (value: boolean) => {};

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // this.isDisabled = isDisabled;
  }

  updateModel(): void {
    this.onModelChange(this.value);
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        this.focus();
      }, 100);
    }
  }

  focus(): void {
    if (this.isValueSelected) {
      this.input.nativeElement.select();
    }
    this.input?.nativeElement.focus();
  }

  onFocusChanged(focused: boolean): void {
    this.focusChanged.emit(focused);
    if (!focused) {
      this.propagateTouched(true);
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (this.keyDown && e.key === 'Enter') {
      this.keyDownEmit.emit(true);
    }
  }

  private genEyeIcon = signal<string>(
    '../../../../assets/images/svgs/closed-eye.png'
  );
  genEyeIconState = computed(this.genEyeIcon);

  onIconClick() {
    this.type = this.type === 'password' ? 'text' : 'password';

    this.genEyeIcon.update(
      (x) =>
        (x =
          this.type === 'password'
            ? '../../../../assets/images/svgs/closed-eye.png'
            : '../../../../assets/images/svgs/open-eye.png')
    );
  }
}
