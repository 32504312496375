import {  inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from 'src/app/auth/data-access/account.service';

export const navGuard: CanActivateFn = (route, state) => {
  const accountService = inject(AccountService);
  const account = accountService.accountState;
  const router = inject(Router);

  console.log(account())
  if (account().role && account().role.includes('Doctor')) {
    router.navigate(['/doctor-dashboard']);

    return true
  }
   if (account().role && account().role.includes('Provider')) {
    router.navigate(['/clinic-dashboard']);
    return true
  }
  else{
    router.navigate(['/authentication/login']);
  }
  return true;
};
