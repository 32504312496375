import { effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from 'src/app/auth/data-access/account.service';

export const authClinicGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const accountService = inject(AccountService);
  const account = accountService.accountState;

  effect(() => {
    (account() && account().token) ||
      router.navigate(['/authentication/login'], {
        queryParams: { returnUrl: state.url },
      });
    console.log('clinic guard')

  });
  let isAuth =
    (account() && account().token && account().role === 'Provider') || false;
  return isAuth;
};
