
export const environment = {
  production: false,
  apiUrl: 'https://healthportal-public-api-dev.remoteapi.ge/healthportal-public-api/Api',
  apiUrlPrivate: 'https://healthportal-private-api-dev.remoteapi.ge/healthportal-private-api/Api',
  gatewayUsername: 'CallCenterCrm',
  gatewayPassword: 'IKHTzpC1CUg12IINOmnO'
};



