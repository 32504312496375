import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { LocalStorageService } from '../services/local-storage.service';
import { inject } from '@angular/core';
import * as moment from 'moment';
import { SHA256 } from 'crypto-js';
import { environment } from 'src/environments/environment.prod';
import { AlertMessageParams, AlertService } from '../services/alert.service';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  map,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { ApiResponseBase } from 'src/app/core/utils/models';
import { AccountService } from 'src/app/auth/data-access/account.service';
import { TranslationService } from '../services/translation.serive';
import { JwtService } from 'src/app/auth/data-access/jwt.service';
import { LoaderService } from '../services/loader.service';
import { getDecodedAccessToken } from '../functions/tokenInfo';

let isRefreshing: boolean = false;
const refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
  null
);




export const httpInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
) => {
const localStorageService = inject(LocalStorageService);
let tokenInfo=getDecodedAccessToken(localStorageService.getKey('Atoken') ?? '')

req = tokenInfo?.UserType && tokenInfo.UserType.includes('Private')
? addTbcGatewayTokenHeader(req) 
: req;

  req = addAccessTokenHeader(req);

  const translationService = inject(TranslationService);
  const alertService = inject(AlertService);
  const loader = inject(LoaderService);
  loader.isLoading.next(true);

  return next(req).pipe(
    map((response) => handleResponse(response, alertService, translationService)),
    catchError((error) => handleError(error, req, next, alertService)),
    finalize(()=>{
      loader.isLoading.next(false);
    })
  );
};

const handleResponse = (
  response: unknown,
  alertService: AlertService,
  translationService: TranslationService
): any => {
  if (response instanceof HttpResponse) {
    const apiResponseBase = response.body as ApiResponseBase;

    if (apiResponseBase.code && apiResponseBase.code !== 1) {
      handleApiError(apiResponseBase, alertService, translationService);
    }
  }
  return response;
};

const handleApiError = (
  apiResponseBase: ApiResponseBase,
  alertService: AlertService,
  translationService: TranslationService
): void => {
  const message = apiResponseBase.message 
  || (apiResponseBase.messagefields ? getdynamicValueErrorMsg(translationService, apiResponseBase, alertService) 
  : getTranslatedErrorMsg(translationService, apiResponseBase, alertService));

  alertService.notification({
    message,
    messageType: 'error',
  });

  if (apiResponseBase?.message?.includes('ტოკენი არავალიდურია')) {
    setTimeout(() => {
      const account = inject(AccountService);
      account.logOut();
      window.location.reload();
    }, 0);
  }
};


const getdynamicValueErrorMsg = (translationService: TranslationService,apiResponseBase:ApiResponseBase,alertService:AlertService): string => {

  // const dummy:any = {
  //   code: -3,
  //   message: null,
  //   messagefields: {
  //     invalidLoginCount: 3, 
  //     UserPasswordMinLengh: 8,
  //     CheckPreviousPasswordsQuantity: 5,
  //     ResendOtpIntervalSeconds: 30,
  //   },
  //   data: null,
  // };
  const messagefields = apiResponseBase.messagefields;
  
  if (translationService.translationsLoaded()) {
    const errorCodeParam = {
      statusCode: apiResponseBase.code,
    };
  
    const messageTxt = alertService.getMessageTxt(errorCodeParam);
    let translated = translationService.translocoService.translate(messageTxt);
  
    for (const key in messagefields) {
      if (messagefields.hasOwnProperty(key)) {

        const placeholder = new RegExp(`{${key}}`, 'g');
        translated = translated.replace(placeholder, messagefields[key]);
      }
    }
    return translated; 
  }
  return translationService.translocoService.translate('Unknown_error');
};


const getTranslatedErrorMsg = (translationService: TranslationService,apiResponseBase:ApiResponseBase,alertService:AlertService): string => {

  const errorCodeParam: AlertMessageParams = {
    statusCode: apiResponseBase.code,
  };
  const messageTxt = alertService.getMessageTxt(errorCodeParam);

  return translationService.translationsLoaded()
    ? translationService.translocoService.translate(messageTxt)
    : '';
};

const handleError = (
  error: any,
  req: HttpRequest<any>,
  next: HttpHandlerFn,
  alertService: AlertService
): Observable<HttpEvent <unknown>> => {
  if (error.status === 401) {
    return handle401Error(req, next);
  } else {
    alertService.notification({
      message: error.message,
      messageType: 'error',
    });
    return throwError(() => error);
  }
};

function getTbcGatewayTokenValue(): string {
  let date = moment().utc().format('YYYY-MM-DD');
  let dataToEncode = `${environment.gatewayUsername}${environment.gatewayPassword}${date}`;
  let tbcAuthHeaderValue = SHA256(dataToEncode).toString();
  let headerValue = `ver=2.0;signature=${tbcAuthHeaderValue}`;
  console.log(headerValue);
  return headerValue;
}

function addTbcGatewayTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
  return request.clone({
    setHeaders: {
      tbcinsauth: getTbcGatewayTokenValue(),
    },
  });
}

function addAccessTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
  const lsService = inject(LocalStorageService);

  var accessToken = lsService.getKey('Atoken');
  if (accessToken == undefined) {
    return request;
  }
  // serverless uploading to S3 doesnot requires Bearer token authorization.
  // if (
  //   request.method == 'PUT' &&
  //   request.url.includes('amazonaws.com/Attachments')
  // ) 
  
  // {
  //   return request.clone({});
  // } 
  else {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

function handle401Error(
  request: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  if (!isRefreshing) {
    const account = inject(AccountService);
    const jwtService = inject(JwtService);

    isRefreshing = true;
    refreshTokenSubject.next(null);
    return account.refreshToken().pipe(
      switchMap((data: any) => {
        isRefreshing = false;
        var accessToken = jwtService.getToken('Atoken');
        refreshTokenSubject.next(accessToken);
        return next(addAccessTokenHeader(addTbcGatewayTokenHeader(request)));
      })
    );
  } else {
    return refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap((accessToken) => {
        return next(addAccessTokenHeader(addTbcGatewayTokenHeader(request)));
      })
    );
  }
}
