import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { filter, map } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { registerLocaleData } from '@angular/common';
import localeKa from '@angular/common/locales/ka';
import localeEn from '@angular/common/locales/en';

export type Languages = 'ka' | 'en';

export interface LanguageTypes {
  code: Languages;
  icon: string;
}

@Injectable({ providedIn: 'root' })
export class TranslationService {
  readonly localStorage = inject(LocalStorageService);
  readonly translocoService = inject(TranslocoService);

  private activeLangCode = signal<string>(
    this.translocoService.getActiveLang()
  );
  private activeLang = signal<string>(
    `/assets/images/flag/icon-flag-${this.translocoService.getActiveLang()}.svg`
  );
  private languages = signal<LanguageTypes[]>(this.getAvailableLangs());

  activeLangCodeState = computed(this.activeLangCode);
  activeLangState = computed(this.activeLang);
  availableLangState = computed(this.languages);


 

  translationsLoaded = toSignal<boolean>(
    this.translocoService.events$.pipe(
      filter((event) => event.type === 'translationLoadSuccess'),
      map((event) => !!event)
    )
  );

  private getAvailableLangs(): LanguageTypes[] {
    return this.translocoService.getAvailableLangs().map((code) => ({
      code: code as Languages,
      icon: `/assets/images/flag/icon-flag-${code}.svg`,
    }));
  }

  public setActiveLanguage(languageTypes: LanguageTypes) {
    this.translocoService.setActiveLang(languageTypes.code);
    this.localStorage.saveKey('mediLang', languageTypes.code);
    this.activeLang.update((x) => (x = languageTypes.icon));
    this.activeLangCode.update((x) => (x = languageTypes.code));

    if (languageTypes.code === 'ka') {
      registerLocaleData(localeKa, languageTypes.code);
    }
    if (languageTypes.code === 'en') {
      registerLocaleData(localeEn, languageTypes.code);
    }
  }
}
