import { Component, inject, Inject, Optional } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/shared/shared-module/material.module';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { JsonPipe, NgIf } from '@angular/common';
import { NavItem } from './layout/nav-item/nav-item';
import { navItems } from './layout/sidebar/sidebar-data';
import { FilterPipe } from 'src/app/shared/pipes/filter.pipe';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SharedModule } from '../shared-module/shared';

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [
    RouterModule,
    MaterialModule,
    TablerIconsModule,
    FormsModule,
    InputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    NgIf,
    JsonPipe,
    FilterPipe,
    SharedModule
  ],
  template: `
  <ng-container *transloco="let t">
    <div class="p-24 p-b-0">
      <div class="row">
        <div class="col-10">
          <form [formGroup]="form">
            <app-input
              [placeholder]="'ძებნა'"
              formControlName="search"
              (keyDownEmit)="onSearch()"
              [keyDown]="true"
            >
            </app-input>

            <!-- <div>
              <app-button
                [text]="'Search'"
                (btnClick)="onSearch()"
              ></app-button>
            </div> -->
          </form>
        </div>

        <div class="col-2 d-flex justify-content-end">
          <button
            mat-icon-button
            mat-dialog-close
            class="d-flex justify-content-center"
          >
            <i-tabler name="x" class="icon-18 d-flex"></i-tabler>
          </button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography search-dialog">
      <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">
        {{ t(data.title) }}
      </h4>

      @for(item of dialogData|filter:f['search'].value!:'displayName'; track
      item.displayName) {
      <!-- [routerLink]="[item.route]" -->
      <a
        mat-dialog-close
        class="p-y-12 text-decoration-none d-block"
        (click)="navigateTo(item)"
      >
        <h5 class="f-s-14 f-w-500 d-block mat-subtitle-1 m-0">
          {{ item.displayName }}
        </h5>
        <span class="f-s-12 mat-body-2">{{ item.route }}</span>
      </a>
      } @if (!(dialogData |
      filter:f['search'].value!:'displayName').length&&f['search'].value) {
      <div style="display: grid;width:100%; grid-gap: 30px;">
        <div style="place-self: center center;">
        
      <img style="width: 100%; height:200px" src="/assets/images/backgrounds/errorimg.svg" alt="error-bg" />
        
        <h2 style="text-align: center;color:black;font-weight:700" class="caps-uppercase" >{{ t('Not_found') }}</h2>
          <p>{{t('Not_found_description') }}</p>
          
        </div>
        <div style="place-self: center center;">
          <app-button
            [text]="t('Open_Patient_history')"
            (btnClick)="onHistoryOpen()"
          ></app-button>
        </div>
      </div>
      }
    </mat-dialog-content>

    </ng-container>
  `,
})
export class AppSearchDialogComponent {
  router = inject(Router);

  constructor(
    @Optional() public dialogRef: MatDialogRef<AppSearchDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { dialogData: NavItem[]; title: string }
  ) { }

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });
  searchText: string = '';
  navItems = navItems;

  dialogData: NavItem[] = this.data.dialogData.filter(
    (navitem) => navitem.displayName
  );

  form = new FormGroup({
    search: new FormControl(''),
  });

  get f() {
    return this.form.controls;
  }

  onSearch() {
    console.log(this.form.value);
  }
  onHistoryOpen() {
    this.router.navigate(['patient-history/patient-history-details/create']);
    this.dialog.closeAll();
  }
  navigateTo(item: NavItem) {
    console.log(item);
  }
}
