import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }


  isJSON(str: any) {

    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  getKey(key: string) {

    if (window.localStorage[key]?.length) {
      return this.isJSON(window.localStorage[key]) ? JSON.parse(window.localStorage[key]) : window.localStorage[key]
    }
    return
  }

  saveKey(key: string, value: string | any) {
    window.localStorage[key] = JSON.stringify(value);
  }

  destroyKey(key: string) {
    window.localStorage.removeItem(key);
  }
}
