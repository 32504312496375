import { NgModule } from "@angular/core";
import { NgxPermissionsModule } from "ngx-permissions";
import { TranslocoModule } from "@ngneat/transloco";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { NgClass, NgComponentOutlet, NgFor, NgIf, NgOptimizedImage, NgSwitch, NgSwitchCase } from "@angular/common";
import { ValidationHelper } from "../pipes/validationHelper.pipe";
import { ValidationErrorsDirective } from "../directives/validationErrors.directive";
import { DateToStringPipe } from "../pipes/date-to-string.pipe";
import { RowDateToDatePipe } from "src/app/shared/pipes/row-date-to-date.pipe";
// import { TranslocoModule } from "@jsverse/transloco";



@NgModule({
  imports: [
    NgxPermissionsModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgComponentOutlet,
    NgOptimizedImage,
    ValidationHelper,
    ValidationErrorsDirective
  ],
  exports: [
    NgxPermissionsModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgComponentOutlet,
    NgOptimizedImage,
    ValidationHelper,
    ValidationErrorsDirective
  ],

  providers: [
    DateToStringPipe,
    RowDateToDatePipe
  ],
  declarations: []
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
    };
  }
}
