import {
  ApplicationConfig,
  provideZoneChangeDetection,
  importProvidersFrom,
  APP_INITIALIZER,
  LOCALE_ID,
} from '@angular/core';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { routes } from './app.routes';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideClientHydration } from '@angular/platform-browser';

import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { MaterialModule } from './shared/shared-module/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PermissionsService } from './shared/services/permissions.service';
import { provideTransloco } from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { TranslocoHttpLoader } from './shared/services/transloco-loader';
import { Languages } from './shared/services/translation.serive';
import { registerLocaleData } from '@angular/common';
import localeKa from '@angular/common/locales/ka'; 
import localeEn from '@angular/common/locales/en'; 
import { appInitializer } from './app.initializer';
import { AccountService } from './auth/data-access/account.service';
import { httpInterceptor } from './shared/interceptors/http.interceptor';
// const translateModuleConfig: TranslateModuleConfig = {
//   defaultLanguage: 'ka',
//   loader: {
//     provide: TranslateLoader,
//     useFactory: HttpLoaderFactory,
//     deps: [HttpClient],
//   },
// };

// export function HttpLoaderFactory(http: HttpClient): any {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

function getStoredLang() {
  return  localStorage.getItem('mediLang')
    ? JSON.parse(localStorage.getItem('mediLang')??'')  as Languages
    : 'ka';
}

registerLocaleData(localeKa, 'ka'); //register georgian locale language

export const appConfig: ApplicationConfig = {
  
  providers: [

    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withComponentInputBinding()
    ),
    provideHttpClient(
      withInterceptors([httpInterceptor]),
    ),

 
    provideClientHydration(),
    provideAnimationsAsync(),
    importProvidersFrom(
      HttpClient,
      FormsModule,
      ReactiveFormsModule,
      MaterialModule,
      NgxPermissionsModule.forRoot(),
      // TranslateModule.forRoot(translateModuleConfig),
      TablerIconsModule.pick(TablerIcons),
      NgxPermissionsModule.forRoot(),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      })
    ),


    {
      provide: APP_INITIALIZER,
      useFactory: (ds: PermissionsService, ps: NgxPermissionsService) =>
        function () {
          return ds.load().then((data) => {
            if (data == null) return null;
            return ps.loadPermissions(data);
          });
        },
      multi: true,
      deps: [PermissionsService, NgxPermissionsService,AccountService],
    },
    provideTransloco({
      config: {
        availableLangs: ['ka', 'en'],
        defaultLang: getStoredLang(),
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        // prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),

    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        ka: 'ka-GE',
      },
      
    }),
    
    // { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },

    // {
    //   provide: LOCALE_ID,
    //   useValue: 'ka',
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [AccountService],
    // },
  ],
};
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }
