import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class JwtService {


  getToken(tokenType: string): string {
    return window.localStorage[tokenType];
  }

  saveToken(tokenType:string,token:string) {
    window.localStorage[tokenType] = token;
  }

  destroyToken(tokenType:string) {
    window.localStorage.removeItem(tokenType);
  }

}
