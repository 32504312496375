import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rowDateToDate',
  standalone: true
})
export class RowDateToDatePipe implements PipeTransform {

  transform(value: Date | string): string {
    if (!value) return '';

    const date = new Date(value);
    const day = date.getDate();
    const monthNames = ['იან.', 'თებ.', 'მარტ.', 'აპრ.', 'მაი.', 'ივნ.', 'ივლ.', 'აგვ.', 'სექ.', 'ოქტ.', 'ნოე.', 'დეკ.'];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day} ${monthNames[date.getMonth()]} ${year} ${hours}:${minutes}`;
  }

}
