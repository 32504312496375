import { computed, inject, Injectable, signal } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { NgxPermissionsService } from 'ngx-permissions';
import { LocalStorageService } from './local-storage.service';
import { AccountService } from 'src/app/auth/data-access/account.service';

export class TokenData {
  ClinicId: string;
  ClinicName: string;
  CoreUserId: string;
  FullName: string;
  UserId: string;
  aud: string;
  exp: number;
  expiration: string;
  UserRole: string;
  name: string;
  nameIdentifier: string;
  sid: string;
  iss: string;
  nbf: number;
}
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  authToken: string;
  decodedTokenInfo: any;

  readonly permissionsService = inject(NgxPermissionsService);
  readonly localStorageService = inject(LocalStorageService);
  readonly accountService = inject(AccountService);

  constructor() {}

  private userTokenInfo = signal<TokenData>(new TokenData());
  public userTokenInfoState = computed(this.userTokenInfo);

  load(): Promise<string[]> {
    console.log('permission load');
    this.authToken = this.localStorageService.getKey('Atoken') ?? '';
    this.decodedTokenInfo = this.getDecodedAccessToken(this.authToken);
    return new Promise<string[]>((resolve) => {
      if (this.decodedTokenInfo) {
        this.userTokenInfo.update((x) => (x = this.decodedTokenInfo));
        this.accountService.populate(this);
        resolve(
         [this.decodedTokenInfo.UserRole]
        );
      }
      resolve(
        ['Init']
       );
    });
  }


  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  hasPermission(name: string) {
    return this.permissionsService.getPermission(name) !== undefined;
  }

    // get decodedInfo() {
  //   return this.decodedTokenInfo;
  // }
}
