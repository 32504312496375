import { Pipe } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

export type PatternTypes =
  | 'email'
  | 'mobNumber'
  | 'onlyNumbers'
  | 'onlyAZ'
  | 'personalNumber'
  | 'none';

@Pipe({
  name: 'validationFormat',
  standalone: true,
})
export class ValidationHelper {
  constructor(private translateService: TranslocoService) {}

  transform(source: any, name: any, patternTypes?: PatternTypes): string[] {
    if (source instanceof FormControl) {
      return this.format((source as FormControl).errors, name, patternTypes);
    }
    return this.format(source as ValidationErrors, name, patternTypes);
  }

  format(
    errors: ValidationErrors | null,
    name: string,
    patternTypes?: PatternTypes
  ): string[] {
    let messages: string[] = [];
    for (let errorName in errors) {
      switch (errorName) {
        case 'required':
          messages.push(this.translateService.translate('Error_required'));
          break;
        case 'pattern':
          if (patternTypes?.includes('mobNumber')) {
            messages.push(this.translateService.translate('Error_incorrect_mob'));
          }
          if (patternTypes?.includes('email')) {
            messages.push(
              this.translateService.translate('Error_incorrect_email')
            );
          }
          break;
      }
    }
    return messages;
  }
}
