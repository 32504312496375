
import { Component, signal } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  template: `
    <div class="branding">
      <a href="/">
        <img
          [src]="
          !options.sidenavCollapsed ?
          './assets/images/logos/light-logo-tbc.svg' :
          './assets/images/logos/tbc-logo.svg'
          "
          class="align-middle m-2"
          alt="logo"
        />
      </a>
    </div>
  `,
})
export class BrandingComponent {

  constructor(private settings: CoreService) { }

  options = this.settings.options()
}
