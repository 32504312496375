<div class="snackbar-container">
    <img 
      src="../../../../assets/images/svgs/check-circle-filled.svg" 
      *ngIf="data.messageType === 'success'">

    <img 
      src="../../../../assets/images/svgs/exclamation-circle-filled.svg" 
      *ngIf="data.messageType === 'error'">
      
    <span 
    [ngClass]="{
      'error': data.messageType === 'error',
      'success': data.messageType === 'success',
      'warning': data.messageType === 'warning'
    }"
    class="caps-regular">{{ data.message }}</span>
    <mat-icon *ngIf="data.showClose" (click)="onClose()">close</mat-icon>
  </div>