import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getDecodedAccessToken } from '../shared/functions/tokenInfo';
import { LocalStorageService } from '../shared/services/local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  readonly http = inject(HttpClient);
  readonly localStorageService = inject(LocalStorageService);

  get getApiUrl (){
  
  let tokenInfo=getDecodedAccessToken(this.localStorageService.getKey('Atoken') ?? '')
   switch (tokenInfo?.UserType) {
      case 'Private':
        return environment.apiUrlPrivate
      case 'Public':
        return environment.apiUrl
      default:
        return environment.apiUrl
    }
  }

  get(path: string, data: any = {}, props = {}): Observable<any> {
    let httpParams = new HttpParams();
    Object.keys(data).forEach(function (key) {
      var item = data[key];
      if (item != undefined && item != 'undefined') {
        if (Array.isArray(item)) {
          for (var i = 0; i < item.length; i++) {
            httpParams = httpParams.append(key, item[i]);
          }
        } else {
          httpParams = httpParams.append(key, item);
        }
      }
    });

    console.log(`${this.getApiUrl}${path}`)
    return this.http.get(`${this.getApiUrl}${path}`, {
      ...props,
      params: httpParams,
    });
  }

  put(path: string, body: Object = {}): Observable<any> {
    this.sanitizeBody(body);
    return this.http.put(
      `${this.getApiUrl}}${path}`,
      // JSON.stringify(body)
      body
    );
  }

  post(path: string, body: Object = {}, options: any = {}): Observable<any> {
    this.sanitizeBody(body);
    return this.http.post(`${this.getApiUrl}${path}`, body, options);
  }
  delete(path: any, body: any = null): Observable<any> {
    this.sanitizeBody(body);
    return this.http.delete(`${environment.apiUrl}${path}`, { body: body });
  }

  private sanitizeBody(body: any): void {
    for (var param in body) {
      if (
        body[param] == undefined ||
        body[param] == null ||
        body[param] == 'undefined' ||
        body[param] == 'null'
      ) {
        delete body[param];
      }
    }
  }
}
